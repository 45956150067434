body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

canvas.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  max-width: 900px;
  height: 100% !important;
}

div.max-w-header {
  max-width: calc(100vw - 36px);
}

.font-roboto {
  font-family: 'Roboto', sans-serif;
}

.bg-fb-blue {
  background-color: #0866ff;
}

.social-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 4px;
}

.max-w-130 {
  max-width: 520px;
}

div[role='button'] {
  cursor: default;
}

.min-w-108 {
  min-width: 424px;
}

.min-w-166 {
  min-width: 664px;
}

.public-DraftEditorPlaceholder-inner {
  --tw-placeholder-opacity: 1;
  color: rgb(195 207 223 / var(--tw-placeholder-opacity));
}

.amber-warning {
  background-color: #fdf0e8;
}

.grecaptcha-badge {
  visibility: hidden;
}
